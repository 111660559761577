<template lang="pug">
  b-row
    b-col.mb-4.mb-xl-0(
      md='12'
      lg='12'
      xl='5')
      b-card.manager-accounts-account-panel.card-white.card-no-gutters.shadow.h-100(no-body)
        b-card-body.d-flex.justify-content-start.align-items-center
          template(v-if='panelShow')
            .panel-picture.mr-4
              b-avatar.wc-bubble.wc-bubble-md.wc-bubble-br.mr-3.shadow(
                :src="$getDeep(panel.data, 'attributes.portrait_url')"
                variant='white'
                alt='')
                template(v-if="!$getDeep(panel.data, 'attributes.portrait_url')" #default)
                  .wc-bubble-content.wc-bubble-linked.bg-primary
                    .i-wrapper
                      i.fal.fa-fw.fa-2x.fa-user.text-white

            .panel-info
              .panel-info-profile(v-if='panel.rs.profile')
                | {{ $getDeep(panel.rs, 'profile.attributes.appellation') }}
                .font-weight-bold
                  | {{ $getDeep(panel.rs, 'profile.attributes.firstname') }} {{ $getDeep(panel.rs, 'profile.attributes.lastname') }}
              .panel-info-identity.small.text-muted.text-break(v-if="$getDeep(panel.rs, 'identity.attributes.username')")
                | @{{ $getDeep(panel.rs, 'identity.attributes.username') }}
              .panel-info-email.small.text-break
                | {{ panel.data.attributes.email }}
          template(v-else)
            wc-loaders-heading-avatar
    b-col.mb-4.mb-xl-0(
      md='12'
      lg='12'
      xl='4')
      b-card.manager-accounts-account-panel.card-white.card-no-gutters.shadow.h-100(no-body)
        b-card-body
          template(v-if='panelShow')
            b-list-group.justify-content-start.p-0.h-100(flush)
              b-list-group-item.border-0(
                :title="$t('activerecord.models.organization.other')")
                span.fa-stack.mr-2.text-primary
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-building
                span
                  | {{ $tc('organization.counts', panel.data.attributes.organizations_count, { count: panel.data.attributes.organizations_count } ) }}
              b-list-group-item.border-0(
                :title="$t('activerecord.models.machine.other')")
                span.fa-stack.mr-2.text-primary
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-conveyor-belt-alt
                span
                  | {{ $tc('machine.counts', panel.data.attributes.machines_count, { count: panel.data.attributes.machines_count } ) }}
          template(v-else)
            wc-loaders-list(:lines='5')
    b-col(
      md='12'
      lg='12'
      xl='3')
      b-card.manager-accounts-account-panel.card-white.card-no-gutters.shadow.h-100(no-body)
        b-card-body
          template(v-if='panelShow')
            b-list-group.justify-content-start.p-0.h-100(flush)
              b-list-group-item.border-0(
                :title="$t('activerecord.attributes.shared.updated_at')")
                span.fa-stack.mr-2.text-primary
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-calendar-check
                span
                  | {{ panel.data.attributes.updated_at }}
              b-list-group-item.border-0(
                :title="$t('activerecord.attributes.shared.created_at')")
                span.fa-stack.mr-2.text-primary
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-calendar-plus
                span
                  | {{ panel.data.attributes.created_at }}
          template(v-else)
            wc-loaders-list(:lines='5')
</template>

<script>
import { Panel } from '@common/panel'
import WcLoadersHeadingAvatar from '@components/shared/loaders/WcLoadersHeadingAvatar'
import WcLoadersList from '@components/shared/loaders/WcLoadersList'

export default {
  name: 'manager-accounts-account-panel',
  mixins: [Panel],
  components: {
    WcLoadersHeadingAvatar,
    WcLoadersList,
  },
  watch: {
    panel() {
      this.panel.rs.profile = this.panelDataIncluded(
        this.$getDeep(this.panel.data, 'relationships.profile.data.type'),
        this.$getDeep(this.panel.data, 'relationships.profile.data.id')
      )
      this.panel.rs.identity = this.panelDataIncluded(
        this.$getDeep(this.panel.data, 'relationships.identity.data.type'),
        this.$getDeep(this.panel.data, 'relationships.identity.data.id')
      )
    },
  },
  data() {
    return {
      cacheType: 'manager/accounts/getPanel',
      cachePayload: { id: this.$route.params.account_id },
    }
  },
}
</script>
